var Web3 = require("web3");
var NewWeb3 = require("newweb3");
let forge = require('node-forge');
let main = require('../config.json').web3URL;
let web3 = new Web3(new Web3.providers.HttpProvider(main));
let newweb3 = new NewWeb3(new Web3.providers.HttpProvider(main));

const TOKEN_ADDRESS = require('../config.json').tokenAddress;
const TOKEN_ABI = require('../Token.json').abi;

const getNonce = (account) => {
  return new Promise((resolve, reject) => {
    web3.eth.getTransactionCount(account, (error, result) => {
      if(error) reject(error);
      resolve(web3.utils.toHex(result));
    })
  })
}

const getGasPrice = () => {
  return new Promise((resolve, reject) => {
    web3.eth.getGasPrice((error, result) => {
      if(error) reject(error);
      resolve(web3.utils.toHex(result*2));
    })
  })
}

const sendRawTransaction = ({to,value,data,privateKey,nonce},rawTx,dispatch,done) => {
  var Tx = require('ethereumjs-tx');
  let tx = new Tx(rawTx);
  const privateKeyBuffer = Buffer.from(privateKey, 'hex');
  tx.sign(privateKeyBuffer);
  let serializedTx = tx.serialize();
  console.log("called ");
  web3.eth.sendSignedTransaction('0x' + serializedTx.toString('hex'),(err,hash)=>{
    console.log({err,hash});
    // setTimeout(()=>{
      if(!err && hash.transactionHash) done(err,hash);
      return;
	  // },1000);
  }).on('error',(err)=>{
    console.log(err);
    if(! (err.message.includes("Transaction has been reverted by the EVM") || err.message.includes("insufficient funds"))) {
      setTimeout(()=>{
        Promise.all([getGasPrice()]).then(values => {
          nonce = web3.utils.toHex(web3.utils.hexToNumber(nonce)+1);
          const rawTx = {
            gasLimit: web3.utils.toHex(200000),
            gasPrice: values[0],
            nonce,
            to,
            value,
            data
          };
          return({rawTx,nonce});
        }).then(({rawTx,nonce})=> {
            console.log("what");
            sendRawTransaction({to,value,data,privateKey,nonce},rawTx,dispatch,done);
        });
      },500);
    } else {
      if(err.message.includes("insufficient funds")){
        return done(new Error('Transaction cost exceeds ETH Balance at address, remember the transaction includes small fees for the miner.'), null)
      }
    }
  });
}

const submitTransaction = ({account,to,value,data,privateKey},dispatch,done) => {
  Promise.all([getNonce(account), getGasPrice()]).then(values => {
    const rawTx = {
      gasLimit: web3.utils.toHex(200000),
      gasPrice: values[1],
      nonce: values[0],
      to,
      value,
      data
    };
    return({rawTx,nonce:values[0]});
  }).then(({rawTx,nonce})=> {
      sendRawTransaction({to,value,data,privateKey,nonce},rawTx,dispatch,(err,hash)=>{
        done(err,hash);
        return;
      })
  }).catch((err) => {
     done(err, null);
  });
}

const encrypt = function(message, password) {
    var salt = forge.random.getBytesSync(128);
    var key = forge.pkcs5.pbkdf2(password, salt, 4, 16);
    var iv = forge.random.getBytesSync(16);
    var cipher = forge.cipher.createCipher('AES-CBC', key);
    cipher.start({iv: iv});
    cipher.update(forge.util.createBuffer(message));
    cipher.finish();
    var cipherText = forge.util.encode64(cipher.output.getBytes());
    return {cipher_text: cipherText, salt: forge.util.encode64(salt), iv: forge.util.encode64(iv)};
}

const decrypt = function(cipherText, password, salt, iv) {
    var key = forge.pkcs5.pbkdf2(password, forge.util.decode64(salt), 4, 16);
    var decipher = forge.cipher.createDecipher('AES-CBC', key);
    decipher.start({iv: forge.util.decode64(iv)});
    decipher.update(forge.util.createBuffer(forge.util.decode64(cipherText)));
    decipher.finish();
    return decipher.output.toString();
}

let Token = new web3.eth.Contract(TOKEN_ABI,TOKEN_ADDRESS);

function getToken(){
	return Token;
}

function getNewWeb3Token(){
  let Token = new newweb3.eth.Contract(TOKEN_ABI,TOKEN_ADDRESS);
	return Token;
}

module.exports = {
 web3,
 getGasPrice,
 encrypt,
 decrypt,
 submitTransaction,
 getToken,
 getNewWeb3Token
}
