import {web3,encrypt} from './web3'
import {fromPrivateKey} from 'ethereumjs-wallet';
import {
  FETCHED_ACCOUNT,
  FETCHED_METAMASK_ACCOUNT
} from './types';

export function importAccount({privateKey,password},done){
  return function(dispatch,getState){

 		try {
			if(privateKey.substring(0,2) == "0x") privateKey = privateKey.substring(2);
	 		let address = fromPrivateKey(Buffer.from(privateKey, 'hex')).getAddressString();
			let enc = encrypt(privateKey,password);

			let wallet = {
					address,
					privateKey: enc.cipher_text,
					salt: enc.salt,
					iv: enc.iv
			};

			dispatch({
				type:FETCHED_ACCOUNT,
				payload: wallet
			});
    }

    catch(err){
      done(err,null);
      return;
    }
  }

}

export function importAccountMetaMask(){
  return function(dispatch,getState){
  	const desiredNetwork = '1' // '1' is the Ethereum main network ID.

  	// Detect whether the current browser is ethereum-compatible,
  	// and handle the case where it isn't:
  	if (typeof window.ethereum === 'undefined') {
  	  alert('Looks like you need a Dapp browser to get started.')
  	  alert('Consider installing MetaMask!')

  	} else {
  	  let ethereum = window.ethereum;
  	  // In the case the user has MetaMask installed, you can easily
  	  // ask them to sign in and reveal their accounts:
  	  ethereum.enable()

  	  // Remember to handle the case they reject the request:
  	  .catch(function (reason) {
  	    if (reason === 'User rejected provider access') {
  	      // The user didn't want to sign in!
  	    } else {
  	      // This shouldn't happen, so you might want to log this...
  	      alert('There was an issue signing you in.')
  	    }
  	  })

  	  // In the case they approve the log-in request, you'll receive their accounts:
  	  .then(function (accounts) {
  	    // You also should verify the user is on the correct network:
  	    if (ethereum.networkVersion !== desiredNetwork) {
  	      // alert('This application requires the main network, please switch it in your MetaMask UI.')
  	    }

  	    // Once you have a reference to user accounts,
  	    // you can suggest transactions and signatures:
  	    const account = ethereum.selectedAddress

  			dispatch({
  				type: FETCHED_METAMASK_ACCOUNT,
  				payload: account
  			});
  	  });
  	}
  }
}
