import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css/dist/js/materialize.min.js'
import $ from 'jquery';
import {purchaseToken,purchaseTokenMetaMask} from '../actions/purchase';
import {getMinPurchase} from '../actions/balance';

class App extends Component {
  render() {
    let background = '#f8f9fa';
    let opacity = this.props.account || this.props.metamaskAccount ? 1 : 1;
    return (
      <div className="col s6" style={{background,opacity, display: 'block'}}>
        <div style={{ left: '0px', 'marginTop': '30px'}}>
          <img src='emblem.png' style={{'verticalAlign':'middle', 'paddingRight': '10px' }} height={'50px'}/>
          <p style={{color: 'black', 'textTransform': 'uppercase', 'verticalAlign': 'middle', 'lineHeight': '20px' }}>
          Provisional Government of Free Joseon</p>
          <p style={{color: 'black', 'textTransform': 'uppercase', 'verticalAlign': 'middle', 'lineHeight': '20px' }}>
          자유조선 임시정부</p>
        </div>
        <h4 className='black-text center'>Purchase G-Visa</h4>
        <h5 className='black-text center'>G-Visa 구입</h5>
        <br/>
        {this.form()}
        <div className="col s12" style={{background,opacity, zIndex: '-1'}}>
          <br/>
          <br/>
          <p style={{ textAlign: 'left', color: 'black'}}>
            Save your private key in a safe place. Use the same private key to check on and transfer G-Visas. You can also transfer G-Visas to markets like <a href="https://www.opensea.io" target="_blank">OpenSea</a> like other ERC-721 digital assets.
            <span className='center'>For direct <span style={{'fontWeight':'700'}}>BTC</span> donations <a href="https://www.blockchain.com/btc/address/134ytYQnZVAEVV6YZVfx1NBUGc9GY45FBm" target="_blank"><span style={{'textDecoration':'underline'}}>134ytYQnZVAEVV6YZVfx1NBUGc9GY45FBm</span></a> </span>
            and for <span className='center'><span style={{'fontWeight':'700'}}>ETH</span> donations <a href="https://etherscan.io/address/0xe60e1888a6c1b6350ff44c0c388781bf169c2979" target="_blank"><span style={{'textDecoration':'underline'}}>0xe60e1888a6c1b6350ff44c0c388781bf169c2979</span></a></span>
          </p>
          <p style={{ textAlign: 'left', color: 'black'}}>
						Private Key를 안전한 곳에 저장 하세요. 같은 Private Key로 G-Visa 확인과 transfer 할수 있습니다. 다른 ERC-721 digital assets처럼 OpenSea와 같은 시장에서 G-Visa transfer가 가능 합니다.
						BTC로 직접 기부 <a href="https://www.blockchain.com/btc/address/134ytYQnZVAEVV6YZVfx1NBUGc9GY45FBm" target="_blank"><span style={{textDecoration:'underline'}}>134ytYQnZVAEVV6YZVfx1NBUGc9GY45FBm</span></a>
						ETH로 기부 <a href="https://etherscan.io/address/0xe60e1888a6c1b6350ff44c0c388781bf169c2979" target="_blank"><span style={{textDecoration:'underline'}}>0xe60e1888a6c1b6350ff44c0c388781bf169c2979</span></a>
          </p>
        </div>
      </div>
    );
  }
  submitPurchase(){
    let field1 = "gen";
    //let field1 = "";
    // $('#field1').val();
    let field2 = "264f39cab871e4cfd65bf0fed7e4932302916b4e"
    //let field2 = ""
    // $('#field2').val();
    let field3 = "264f39cab871e4cfd65bf0fed7e4932302916b4e"
    //let field3 = ""
    // $('#field3').val();
    let rawValue = $('#amount').val();
    let donation = $('#donation').val();
    let value = parseInt(rawValue)
    let buyButtonText = $("#buyButton").text()

    if (donation !== null && donation !== "") {
      if (parseInt(donation) > 0) {
        value = parseInt(value) + parseInt(donation)
      }
    }

      if(this.props.account && value >= parseInt(this.props.minPurchase) && buyButtonText !== 'Confirm & Buy'){
        let password = $('#pass').val();
        $('#pass').val[0] = null;
        try {
          $('#buyButton').text('Sending...')
          setTimeout(function() {
            $('#checkStatus').text(' Check Status of Transaction')
          }, 3000)
          M.toast({html: 'Processing...',classes: "blue",displayLength:2000});
          this.props.purchaseToken({field1,field2,field3,value,password},(err,hash)=>{
            console.log({err,hash});
            if(!err) {
              M.toast({html: 'Transaction Submitted!',classes: "green",displayLength:2000});
              $('#checkStatus').text('')
            } else {
              console.log({err});
              if(typeof err === 'string' && err.includes("Password")){
                M.toast({html: err,classes:"red",displayLength:2000});
              } else if (err === 'string' && err.includes('balance')) {
                M.toast({html: err,classes:"red",displayLength:5000});
              } else M.toast({html: 'Transaction Unsuccessful! There may not be enough ETH at the address. There must be enough for the miners fee as well.',classes:"red",displayLength:9000});
            }
          });
        } catch (err) {
          $('#checkStatus').text('')
          if(typeof err === 'string') {
            M.toast({html: err,classes:"red",displayLength:5000});
          } else {
            M.toast({html: 'Transaction failed, likely because there was not enough ethereum at the address',classes:"red",displayLength:5000});
          }
        }
      }
      else if(this.props.metamaskAccount && value >= parseInt(this.props.minPurchase) && buyButtonText !== 'Confirm & Buy') {

        $('#buyButton').text('Sending...')
        setTimeout(function() {
          $('#checkStatus').text(' Check Status of Transaction')
        }, 3000)
        M.toast({html: 'Processing...',classes: "blue",displayLength:2000});
        this.props.purchaseTokenMetaMask({field1,field2,field3,value},(err,hash)=>{
          console.log({err,hash});
          if(!err) {
            M.toast({html: 'Transaction Submitted!',classes: "green",displayLength:2000});
            $('#checkStatus').text('')
          } else {
            console.log({err});
            if(typeof err == 'string' && err.includes("Password")){
              M.toast({html: err,classes:"red",displayLength:2000});
            } else M.toast({html: 'Transaction Unsuccessful!',classes:"red",displayLength:2000});
          }
        });
      }
      else if(! (value >= parseInt(this.props.minPurchase))){
        M.toast({html:`Please Enter a Minimum Amount of ${this.props.minPurchase} of ETH`,classes:'red'});
      }
      else if(buyButtonText === 'Confirm & Buy') {
        $('#buyButton').text('Click again to confirm the sending of ' + value + ' ETH')
      }
      else {
        M.toast({html:`Please submit an account before purchasing a G-Visa`,classes:'red'});
      }
      if(buyButtonText !== 'Confirm & Buy') {
        $('#field1').val[0] = null;
        $('#field2').val[0] = null;
        $('#field3').val[0] = null;
        $("#amount").val[0] = null;
        $("#donation").val[0] = null;
        setTimeout(function() {
          $('#buyButton').text('Confirm & Buy')
        }, 12660);
      }
  }
  componentDidMount(){
     $(document).ready(function() {
       M.updateTextFields();
     });
     this.props.getMinPurchase();
  }
  form(){
    return (
      <div className="row" style={{zIndex:'1'}}>
        <form className="col s12" style={{zIndex:'1'}}>
          <div className="row center" style={{zIndex:'1'}}>
            <div className="input-field col s12">
              <input style={{backgroundColor: '#fff'}} id="amount" value={this.props.minPurchase} type="number" className="validate" />
              <label for="amount"> Amount Total ETH (금액 합계 ETH)</label>
              <span class="helper-text black-text" data-error="wrong" data-success="right">1 GENESIS VISA COSTS (토큰 금액): {this.props.minPurchase} ETH</span>
            </div>
            <div className="input-field col s12">
              <input style={{backgroundColor: '#fff'}} id="donation" type="number" />
              <label for="donation"> Additional Donation ETH (추가 기부 ETH)</label>
            </div>
            <div className="input-field col s12" style={{display: 'inline-block', padding: '5%'}}>
              {this.props.account &&
                <div className="input-field col s12">
                  <input style={{backgroundColor: '#fff'}} id="pass" type="password" className="validate" />
                  <label for="pass"> Enter Your Temporary Pin From the Previous Screen (이전 화면에서의 Temporary Pin을 입력 하세요)</label>
                </div>}
               <br />
              {this.props.account && <p className='black-text center' style={{padding: '10px', textAlign: 'left'}}><span style={{paddingTop: '20px', display: 'block'}}><b>SEND <b>ETH</b> TO THIS ADDRESS</b></span><span style={{paddingBottom: '5px' }}>이 주소로 이더(ETH)를 보내 주세요</span><h5 style={{ padding: '10px', fontFamily: 'courier', backgroundColor: '#fff' }}>{this.props.account.address}</h5>You must deposit enough Ethereum to this address <b>before</b> you click "Confirm & Buy". If the transaction fails simply retry using the same private key.<span style={{ display: 'block'}}>"Confirm & Buy"를 클릭 하시기 전에 이더리움이 이 주소에 충분히 입금 돼 있어야 합니다. 만약 거래가 실패 했다면 같은 Private Key로 다시 시도 해 보세요.</span></p>}
              <a id="buyButton" className="waves-effect waves-light btn-large blue" onClick={this.submitPurchase.bind(this)}>Confirm & Buy</a>
              {this.props.account && <a id="checkStatus" href={"https://etherscan.io/token/0x9044c1f34f29d19558c11662cb2de79f858347d9?a=" + this.props.account.address} target="_blank"></a>}
              {this.props.metamaskAccount && <a id="checkStatus" href={"https://etherscan.io/token/0x9044c1f34f29d19558c11662cb2de79f858347d9?a=" + this.props.metamaskAccount} target="_blank"></a>}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    account: state.main.account,
    metamaskAccount: state.main.metamaskAccount,
    minPurchase: state.main.minPurchase
  };
}

export default connect(mapStateToProps, {purchaseToken,purchaseTokenMetaMask,getMinPurchase})(App);
