import React, { Component } from 'react';
import { connect } from 'react-redux';
import Accounts from './Accounts';
import Purchase from './Purchase';
import Tokens from './Tokens';

class App extends Component {
  render() {
    return (
      <div className="row center">
        {!this.props.account && !this.props.metamaskAccount ?
          <Accounts />
          :
          <div>
            <Purchase />
            <Tokens />
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    account: state.main.account,
    metamaskAccount: state.main.metamaskAccount,
  };
}

export default connect(mapStateToProps, {})(App);
