import {
  FETCHED_ACCOUNT,
	FETCHED_METAMASK_ACCOUNT,
	MIN_PURCHASE,
	FETCHED_MY_TOKENS
} from '../actions/types';

let initialState =  {
	tokens: [],
	minPurchase : 0,
	account: null,
	metamaskAccount: null
}

export default function(state: Object = initialState, action) {
  switch(action.type) {
    case FETCHED_ACCOUNT:
      return { ...state, account:action.payload };
		case FETCHED_METAMASK_ACCOUNT:
      return { ...state, metamaskAccount:action.payload };
		case MIN_PURCHASE:
      return { ...state, minPurchase:action.payload };
		case FETCHED_MY_TOKENS:
			return { ...state, tokens:action.payload };
		default:
      return state;
  }
}
