import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from './table';
import {getMyTokens} from '../actions/balance';

class App extends Component {
  constructor(){
    super();
    this.state = {calledInterval:false};
  }
  render() {
    let background = '#173B6F';
    let items = this.props.tokens.map(({id,field1,field2,field3})=>{
      return (
        <tr>
            <td className='center' ><a style={{ fontSize: '16pt', color: 'white', textDecoration: 'underline' }} href={"https://etherscan.io/token/0x9044c1f34f29d19558c11662cb2de79f858347d9?a=" + id} target="_blank">#{id}</a> / 200,000 <span style={{ fontSize: '14pt', color: 'green', paddingRight: '10px' }}>&#10003;</span></td>
            <td className='center' >{field1}</td>
            <td className='center' >{field2}</td>
            <td className='center' >{field3}</td>
        </tr>
      )
    });
    return (
      <div className="col s6" style={{background}}>
        <div style={{ 'marginTop': '30px'}}>
          <img src='gvisa_print.png' style={{'padding': '10px' }} alt={'G-Visa'} height={'60px'}/>
        </div>
        <h4 className='white-text center'> Manage G-Visas </h4>
        {this.props.metamaskAccount && <p className='white-text center'> <b>View owner's G-Visa(s) on Ethereum: </b> <a href={"https://etherscan.io/token/0x9044c1f34f29d19558c11662cb2de79f858347d9?a=" + this.props.metamaskAccount} target="_blank">{`${this.props.metamaskAccount}`} </a></p>}
        {this.props.account && <p className='white-text center'> <b>View owner's G-Visa(s) on Ethereum: </b> <a href={"https://etherscan.io/token/0x9044c1f34f29d19558c11662cb2de79f858347d9?a=" + this.props.account.address} target="_blank">{`${this.props.account.address}`}</a></p>}
        <br/>
        <Table headers={['G-Visa ID','METADATA','GEO', 'TYPE']} items={items} classes='white-text center striped'/>
      </div>
    );
  }
  componentDidMount(){
    if(!this.state.calledInterval){
      if(this.props.account){
        this.props.getMyTokens(this.props.account.address);
        setInterval(()=>{
          this.props.getMyTokens(this.props.account.address);
        },5000);
        this.setState({calledInterval:true});
      }
      else if(this.props.metamaskAccount) {
        this.props.getMyTokens(this.props.metamaskAccount);
        setInterval(()=>{
          this.props.getMyTokens(this.props.metamaskAccount);
        },5000);
        this.setState({calledInterval:true});
      }
    }
  }
  componentDidUpdate(){
    if(!this.state.calledInterval){
      if(this.props.account){
        this.props.getMyTokens(this.props.account.address);
        setInterval(()=>{
          this.props.getMyTokens(this.props.account.address);
        },5000);
        this.setState({calledInterval:true});
      }
      else if(this.props.metamaskAccount) {
        this.props.getMyTokens(this.props.metamaskAccount);
        setInterval(()=>{
          this.props.getMyTokens(this.props.metamaskAccount);
        },5000);
        this.setState({calledInterval:true});
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    account: state.main.account,
    metamaskAccount: state.main.metamaskAccount,
    tokens: state.main.tokens
  };
}

export default connect(mapStateToProps, {getMyTokens})(App);
