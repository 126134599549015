import {web3,getToken,getNewWeb3Token} from './web3'
import {
	MIN_PURCHASE,
	FETCHED_MY_TOKENS
} from './types';

export function getMinPurchase() {
	return function(dispatch){
		getToken().methods.getMinPurchase().call().then((balance)=>{
			dispatch({
				type:MIN_PURCHASE,
				payload: web3.utils.fromWei(String((parseInt(balance) + 1000000000000000)),'ether')
			})
		});
	}
}

export function getMyTokens(account) {
  console.log('get balance of account ' + account)
  return function(dispatch) {
		getToken().methods.balanceOf(account).call().then((balance)=>{
			let tokenPromises = (new Array(parseInt(balance)).fill(0)).map((b,i)=>{
				return new Promise((resolve,reject)=>{
					getToken().methods.tokenOfOwnerByIndex(account,i).call().then((token)=>{
						resolve(token);
					});
				});
			});

			Promise.all(tokenPromises).then((tokenIds) => {
				let promises = tokenIds.map((id)=>{
					return new Promise((resolve,reject)=>{
						getToken().methods.getMetadata(id).call().then((token)=>{
							token[3] = id;
							resolve(token);
						});
					});
				});

				Promise.all(promises).then((tokens)=>{
					let my_tokens = tokens.map((val)=>{
						let id = val[3];
						//let field1 = val[0];
						//let field2 = val[1];
						//let field3 = val[2];
            let perc = (Number(id) / 200000) * 100
						let s = perc.toFixed(3);
						let field1 = "Earliest " + s + "% of G-VISAs";
            if (s > 50) {
						  field1 = "G-VISA";
            }
						let field2 = "FREE JOSEON";
						let field3 = "ERC721";
            let key = field1 + '_' + key;
						return {id,field1,field2,field3};
					});
					dispatch({
						type: FETCHED_MY_TOKENS,
						payload: my_tokens
					});

				});
			});
		});

		}
  }
