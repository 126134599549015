import React, { Component } from 'react';
import { connect } from 'react-redux';
import {importAccountMetaMask,importAccount} from '../actions/accounts';
import Countdown from 'react-countdown-now';
import $ from 'jquery';
import M from 'materialize-css/dist/js/materialize.min.js'

const Completionist = () => <span id="countdownStatus">Issuance period is open.</span>;
// Sat, 24 Mar 2019 12:00:00 UTC
// onClick={this.importMetamask.bind(this)}
class App extends Component {
  importMetamask(){
    this.props.importAccountMetaMask();
  }
  importAccount(){
    let privateKey = $('#priv').val();
    let password = $('#enc').val();
    this.props.importAccount({privateKey,password},(err)=>{
      if(!err) M.toast({html: 'Account Submitted!',classes: "green",displayLength:2000});
      else {
        M.toast({html: 'Could Not Parse Account Format!',classes:"red",displayLength:2000});
      }
    });
  }
  render() {
		this.countdownComplete = false
		const renderer = ({ days, hours, minutes, seconds, completed }) => {
			if (completed) {
				// Render a completed state
				this.countdownComplete = true
				return <Completionist />;
			} else {
				// Render a countdown
				return <span id="countdownStatus">{days}일 DAYS {hours}시간 HOURS {minutes}분 MINS {seconds}초 SECONDS</span>;
			}
		};
    let background = '#f8f9fa';
    return (
      <div className="row col s12" style={{ background, minHeight:'600px',height:'100vh'}}>
        <div style={{ background: '#10284A', color: '#fff', minHeight: '80px', 'textAlign': 'center-vertical', 'borderBottom': '5px solid #B44137' }}>
        <div style={{ background: '#000', minHeight: '10px','paddingBottom': '10px'}}>
        </div>

            <div style={{ background: '#173B72', backgroundImage: 'url("background.jpg")', backgroundRepeat: 'no-repeat', minHeight: '90px', 'paddingTop': '2px', paddingBottom: '50px' }} id={'headerlock'}>
              <div style={{ 'marginTop': '20px'}}>
                <img src='emblem.png' style={{'verticalAlign':'middle', 'paddingRight': '10px' }} height={'50px'}/>
                <p style={{color: 'white', 'textTransform': 'uppercase', 'verticalAlign': 'middle', 'lineHeight': '20px' }}>
                Provisional Government of Free Joseon</p>
                <p style={{color: 'white', 'textTransform': 'uppercase', 'verticalAlign': 'middle', 'lineHeight': '20px' }}>
                자유조선 임시정부</p>
              </div>
                <h3 id={'bgvisaheader'} style={{ 'paddingBottom': '2px', 'fontWeight': '900' }}>BLOCKCHAIN G-Visa</h3>
                <h3 id={'bgvisaheader'} style={{ 'paddingBottom': '10px', 'fontWeight': '900' }}>블록체인 G-Visa</h3>
                <div id='cd' style={{paddingBottom: '5px'}}><a href="https://etherscan.io/token/0x9044c1f34f29d19558c11662cb2de79f858347d9" target={'_blank'} style={{ textDecoration: 'underline'}}>VIEW ON ETHEREUM BLOCKCHAIN</a></div>
                <span id='cd'><a id='cd' href="https://etherscan.io/token/0x9044c1f34f29d19558c11662cb2de79f858347d9" target={'_blank'}>이더리움 블록체인에서 보기</a></span>
            </div>
        <div className='center' style={{paddingTop:'10px', paddingBottom: '30px', paddingLeft: '5%', paddingRight: '5%' }}>
          <h5 className='center' style={{'paddingTop':'10px'}}>Limited issuance of 200,000 anonymous blockchain visas to visit Free Joseon (previously North Korea) upon liberation.</h5>
          <h5 className='center' style={{'paddingBottom':'0px'}}>해방 이후 자유조선을 방문하기 위해 한정발급된 200,000 장의익명 블록체인 비자</h5>
					<br/>
					<span style={{ display: 'block', 'padding':'10px 10px 10px 10px', background: '#f8f9fa', color: 'black', fontWeight: 'bold', overflow: 'no-wrap' }}>
              G-Visas are available now.
							<br/>
              G-VISA 구매가 이제 가능합니다.
							<br/>
					</span>
          <p className='center'>For direct <span style={{'fontWeight':'700'}}>BTC</span> donations <a href="https://www.blockchain.com/btc/address/134ytYQnZVAEVV6YZVfx1NBUGc9GY45FBm" target="_blank"><span style={{'textDecoration':'underline'}}>134ytYQnZVAEVV6YZVfx1NBUGc9GY45FBm</span></a> (BTC 직접 후원) | For direct <span style={{'fontWeight':'700'}}>ETH</span> donations <a href="https://etherscan.io/address/0xe60e1888a6c1b6350ff44c0c388781bf169c2979" target="_blank"><span style={{'textDecoration':'underline'}}>0xe60e1888a6c1b6350ff44c0c388781bf169c2979</span></a> (ETH 직접 후원)</p>
        </div>
        </div>
        <br/>

        <div className="row col s12">
          <div style={{display: 'inline-block', 'verticalAlign': 'middle', padding: '2%', 'textAlign': 'left' }}>
             <div class="post-container">
								<div class="post-content">
							<div class="post-thumb"><img src="gvisa_print.png" style={{ 'padding': '5px', paddingTop: '20px' }} alt={'G-Visa'} width={'368px'}/></div>
						  			<h4 style={{ 'paddingBottom': '10px'}}>Getting Started</h4>
              			<p><span style={{color:'#333' }}>Each G-Visa is assigned an incrementing ID Number in the order it was purchased for example Visa #<b>1</b>, Visa #<b>2</b>, Visa #<b>3</b>. A specfic number cannot be requested although it may be available on <a href={'https://opensea.io/assets/freejoseongenesisvisa'} target="_blank">OpenSea.io</a> or other ERC-721 marketplaces. The issuance ID number is <b>permanent</b> and unique to each G-Visa.</span></p>
              			<p><span style={{color:'#333' }}>각 G-Visa는 발급된 순서대로 ID 번호가 1씩 증가합니다. 예를 들어 Visa #1, Visa #2, Visa #3. 특정 번호를 요청할 수 없으나 OpenSea.io나 다른 ERC-721 시장에서 구할 수는 있습니다. 발급 ID 번호는 영구적이며 G-Visa별로 고유합니다.</span></p>
							 </div>
							</div>
            <ol style={{ 'textAlign': 'left', 'listStyleType': 'square !important', maxWidth: '1200px'}}>
						  <h4 style={{ 'paddingBottom': '10px'}}>Rules & Availability</h4>
              <li>The registration cost is <b>1 ETH</b> per visa for the first 1000 VISAs. Donations can be added and are gratefully accepted.</li>
              <li>The contribution amount and owner are anonymous. No personal data is collected or requested.</li>
              <li>There is a maximum limit of 200,000 G-Visas to be issued.</li>
              <li>One G-Visa is valid for one single entry for a maxmium of <b>45 days</b>.</li>
							<li>For group registrations over 100 or visas seeking to establish commercial activities please contact <a href="mailto:CCDjoin@protonmail.com">Free Joseon</a>.</li>
              <li>Built adhering to the audited <a href="https://github.com/ethereum/EIPs/blob/master/EIPS/eip-721.md" target="_blank">ERC-721</a> smart contract standard.</li>
              <li>G-Visa acceptance is considered provisional policy enforced by Ethereum Blockchain according to the <a href="https://www.ethereum.org" target="_blank">CH Ethereum Foundation</a>.</li>
              <li>G-Visas expire on 1-MARCH-2029 after which all owners must start a new application.</li>
              <li>G-Visas follow relevant customs and port of entry restrictions.</li>
              <li>There no individual limit for the number of G-Visas purchased.</li>
							<li>G-Visas can be exercised only by the owner of the address at which the G-Visa exists according to the Ethereum Blockchain after block <a href="https://etherscan.io/block/7278716" target="_blank">#7278716</a></li>
              <li>Ownership of one or more G-Visas should be considered a contribution to the movement and should not be used for speculative or fiduciary purposes.</li>
            </ol>
            <ol style={{ 'textAlign': 'left', 'listStyleType': 'square !important', maxWidth: '1200px'}}>
						  <h4 style={{ 'paddingBottom': '10px'}}>이용약관</h4>
              <li>1,000장이 발급되기 전까지는 (ID #1000 까지는) 각 비자를 하나 당 <b>1 ETH</b> 에 구매 하실 수 있습니다. 추가로 후원해 주시는 금액은 감사히 받습니다.</li>
              <li>후원금액과 소유자는 익명입니다. 개인정보를 수집하거나 요청하지 않습니다.</li>
              <li>G-Visa 발급은 최대 200,000장으로 한정되어 있습니다.</li>
              <li>각 G-Visa는 체류기간이 <b>45일이며</b> 한 번의 입국이 허용됩니다.</li>
							<li>100명 이상의 단체비자가 필요하시다면 <a href="mailto:CCDjoin@protonmail.com">CCD</a>.로 연락 하십시오.</li>
              <li>사전 감사된 ERC-721 스마트 계약 표준을 사용하고 있습니다. </li>
              <li>임시 정책으로 G-Visa 승인은 CH Ethereum Foundation에 의한 이더리움 블록체인으로 시행합니다.</li>
              <li>G-Visa의 유효 기간은 2029년 3월 1일까지 입니다. 유효기간 만료 후 모든 비자 소유자들은 반드시 재신청을 해야 합니다.</li>
              <li>G-Visa는 무조건 세관과 입국 규제 제한을 받게 됩니다.</li>
              <li>개인으로서의 G-Visa 구매 한도는 없습니다.</li>
							<li>이더리움 블록체인 블록 <a href="https://etherscan.io/block/7278716" target="_blank">#7278716</a> 이후 등록된 G-Visa 주소의 소유자만이 G-Visa를 사용 하실 수 있습니다.</li>
              <li>하나 또는 그 이상의 G-Visa 소유권은 투기 혹은 신탁의 목적으로 사용되여서는 안되며 활동 기여의 의도를 위함 이어야 합니다.</li>
            </ol>
          </div>
          <div>
            </div>

          {this.private()}

        </div>
        <div style={{paddingBottom: '10px', paddingTop: '80px'}}>
          <h6 className='center' style={{marginBottom:'10px', marginTop:'50px'}}> We alone will never give up but with your support we cannot fail. We are eternally grateful.</h6>
          <h6 className='center' style={{marginBottom:'10px', marginTop:'5px'}}>우리는 결코 포기하지 않습니다. 그리고 당신의 지원이 있는 한 절대 실패하지 못합니다. 우리는 영원히 감사할 것입니다.</h6>
        </div>
        <div style={{paddingBottom: '10px', minHeight: '40px', background: '#173B72'}}>
					<h6 className='center' style={{color: 'white'}}>For direct <span style={{ color: 'white', fontSize: '14pt', 'fontWeight':'700'}}>BTC</span> donations <a href="https://www.blockchain.com/btc/address/134ytYQnZVAEVV6YZVfx1NBUGc9GY45FBm" target="_blank"><span style={{'textDecoration':'underline'}}>134ytYQnZVAEVV6YZVfx1NBUGc9GY45FBm</span></a> or direct <span style={{ color: 'white', fontSize: '14pt', 'fontWeight':'700'}}>ETH</span> donations <a href="https://etherscan.io/address/0xe60e1888a6c1b6350ff44c0c388781bf169c2979" target="_blank"><span style={{'textDecoration':'underline'}}>0xe60e1888a6c1b6350ff44c0c388781bf169c2979</span></a></h6>
          <h6 className='center' style={{color: 'white'}}>
            CCDjoin@protonmail.com | <a href="cheollimacivildefense.org" target="_blank">cheollimacivildefense.org</a>
          </h6>
        </div>
        <br/>
      </div>
    );
  }
  private(){
    return (
      <form className="col s12">
        <div id='cd' className="row" style={{padding: '2%', color: '#000', 'backgroundColor': '#eaeaea', 'borderTop': '3px solid #e1e1e1', 'borderLeft': '3px solid #e1e1e1', 'borderBottom': '3px solid #6666', 'borderRight': '3px solid #666666' }}>
        <div className="row col s12">
            <a href='#' onClick={this.importMetamask.bind(this)}>
              <h5 className='center'>Connect MetaMask Wallet<span style={{ display: 'block', fontSize: '12pt'}}>MetaMask 지갑으로 비자를 구매 하십시오.</span></h5>
            </a>
					<br/>
					<br/>
          <h6>Install MetaMask extension for <a href="https://metamask.io/" target="_blank">Google Chrome</a> or <a href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/" target="_blank">Firefox</a></h6>
          <h6><a href="https://metamask.io/" target="_blank">Google Chrome</a> 또는 <a href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/" target="_blank">Firefox</a> 를 위한 MetaMask 확장앱을 설치 하십시오.</h6>
          <div className='row center' style={{ padding: '30px' }}>
            <a id='cd' href='#' onClick={this.importMetamask.bind(this)}>
              <img src='metamask.svg' height={'150px'}/>
            </a>
          </div>
        </div>
          <h2 className='center' style={{padding:'30px', margin: '30px', 'fontWeight': '900' }}> OR </h2>
          <h5 className='center' style={{paddingRight:'20px', paddingLeft: '20px', paddingTop: '30px', backgroundColor: '#173B72', color: '#fff' }}> Buy Visa from supported cryptocurrency exchange. </h5>
          <h5 className='center' style={{paddingRight:'20px', paddingLeft: '20px', paddingBottom: '30px', backgroundColor: '#173B72', color: '#fff' }}> 비자는 지원되는 암호화폐 거래소에서 이더리움으로 구매하십시오.</h5>
					<table style={{ background: 'white', padding: '20px', tableLayout: 'auto', width: '100%', maxHeight: '140px', borderBottom: '3px solid #ccc', borderTop: '5px solid #B44137' }}>
					<tbody>
						<tr>
						<td style={{maxWidth: '45px'}}>
							<a href="https://support.binance.com/hc/en-us/articles/115003670492-How-to-Withdraw-coins" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'binance.png'} />
							</a>
						</td>
						<td style={{maxWidth: '45px'}}>
							<a href="https://support.kraken.com/hc/en-us/articles/360000672763-How-to-withdraw-digital-assets-and-cryptocurrencies-from-your-Kraken-account" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'kraken.png'} />
							</a>
						</td>
						<td style={{maxWidth: '45px'}}>
							<a href="https://gemini24.zendesk.com/hc/en-us/articles/115004710226-How-do-I-withdraw-digital-assets-from-my-Gemini-account-" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'gemini.png'} />
							</a>
						</td>
						<td style={{maxWidth: '45px'}}>
							<a href="#" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'coinbase.png'} />
							</a>
						</td>
						<td style={{maxWidth: '45px'}}>
							<a href="https://www.investinblockchain.com/kucoin-exchange-review/#post-12988" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'kucoin.png'} />
							</a>
						</td>
						<td style={{maxWidth: '45px'}}>
							<a href="https://poloniex.freshdesk.com/support/solutions/articles/1000166423-how-do-i-withdraw-my-coins-" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'poloniex.png'} />
							</a>
						</td>
						<td style={{maxWidth: '45px'}}>
							<a href="https://support.bitfinex.com/hc/en-us/articles/115003291345-How-do-I-withdraw-cryptocurrencies-" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'bitfinex.png'} />
							</a>
						</td>
						<td style={{maxWidth: '45px'}}>
							<a href="https://en.bithumb.com/u3/US302" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'bithumb.png'} />
							</a>
						</td>
						<td style={{maxWidth: '45px'}}>
							<a href="https://support.ethfinex.com/hc/en-us/articles/115002246152-Making-a-Withdrawal" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'ethfinex.svg'} />
							</a>
						</td>
						<td style={{maxWidth: '65px'}}>
							<a href="https://bittrex.zendesk.com/hc/en-us/articles/115000198452-How-can-I-withdraw-my-coins-" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'bittrex.png'} />
							</a>
						</td>
						<td style={{maxWidth: '65px'}}>
							<a href="#" target="_blank">
								<img style={{ padding:'5px', maxWidth: '100%', maxHeight: '100%' }} src={'upbit.jpg'} />
							</a>
						</td>
						</tr>
					</tbody>
					</table>
          <p id='cd' style={{fontSize: '14pt', padding: '2%'}}>
            <ol style={{ left: '0px', float: 'left', 'textAlign': 'left', 'listStyleType': 'square !important', maxWidth: '1200px'}}>
            <h5><span style={{ fontSize: '24pt', fontWeight: '500', color: 'green', paddingRight: '10px' }}>&#10003;</span><b>Create Private Key</b> or <a href='#' onClick={this.importMetamask.bind(this)}>Connect MetaMask Wallet</a></h5>
            <h5>Private Key를 만들거나 MetaMask 지갑을 연결 하십시오.</h5>
            <p style={{ padding: '10px'}}><i>If you already have a private key skip this section and paste it in the text box below then click 'Continue'.</i> <span style={{ display: 'inline-block'}}><i>이미 Private Key가 있다면 이 단계를 지나 아래에 있는 텍스트 상자 안에 개인키를 붙여넣기 한 후 ‘Continue’를 누르십시오.</i></span></p>
              <li style={{ padding: '10px'}}>
								 Visit <a href="https://vintage.myetherwallet.com" target="_blank">https://vintage.myetherwallet.com</a> and close the warning popup window.
								 <span style={{ display: 'inline-block' }}><a href="https://vintage.myetherwallet.com" target="_blank">https://vintage.myetherwallet.com</a> 으로 들어간 후 팝업 창을 닫습니다.</span>
							</li>
              <li style={{ padding: '10px'}}>
								Enter a secure password and download the keystore file. Click 'I understand. Continue.'
								 <span style={{ display: 'inline-block' }}>보안 암호를 입력한 후 keystore 파일을 다운로드합니다. ‘I understand. Continue’를 클릭합니다.</span>
							</li>
              <li style={{ padding: '10px'}}>
								Copy the Private Key and return to this page. Paste the Private Key in the text box, assign a temporary pin, and click 'Continue'.
								 <span style={{ display: 'inline-block' }}>Private Key를 복사하고 이 페이지로 돌아옵니다. Private Key를 텍스트 상자에 붙여넣고 임시 PIN을 지정한 후 ‘Continue’를 클릭합니다.</span>
							</li>
            </ol>
          <div className="input-field col s12">
            <input style={{backgroundColor: '#fff'}} id="priv" type="password" className="validate"/>
            <label for="priv"> Click to Enter Private Key (클릭하여 Private Key를 입력합니다.)</label>
          </div>
          <div className="input-field col s12">
            <input style={{backgroundColor: '#fff', fontSize: '10pt' }} id="enc" type="password" className="validate" />
            <label for="enc"> Click to Enter Temporary Pin (클릭하여 Temporary Pin을 입력합니다.)</label>
          </div>
          </p>
          <div id="cd" style={{padding:'20px', float: 'left'}}>
            <a href="#" className="waves-effect waves-light btn-large red" onClick={this.importAccount.bind(this)}>Continue</a>
          </div>
        </div>
          <br/>
      </form>
    )
  }

}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, {importAccountMetaMask,importAccount})(App);
