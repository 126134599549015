import {web3,decrypt,submitTransaction,getNewWeb3Token} from './web3'
import {fromPrivateKey} from 'ethereumjs-wallet';

export function purchaseTokenMetaMask({field1,field2,field3,value},done){
	return function(dispatch,getState){

		let ethereum = window.ethereum;
		let account = ethereum.selectedAddress;

	  const parameters = [{
	    from: account,
	    to: getNewWeb3Token().address,
	    value: "0x" + Number(web3.utils.toWei(String(value), 'ether')).toString(16),
			gas: web3.utils.toHex(4000000),
			// data: getToken().methods.purchaseToken(field1,field2,field3).encodeABI()
			data: getNewWeb3Token().methods.purchaseToken().encodeABI()
	  }];

	  const payload = {
	    method: 'eth_sendTransaction',
	    params: parameters,
	    from: account,
	  }

		console.log({payload});

	  ethereum.sendAsync(payload, function (err, response) {
	    const rejected = 'User denied transaction signature.'
			console.log({err,response});
	    if (response.error && response.error.message.includes(rejected)) {
				done(`Send transaction due to a permission error.`);
	    }
	    if (err) {
	      done(err);
	    }

	    if (response.result) {
	      const txHash = response.result;
				done(null,txHash);
	    }
	  });
	}
}

export function purchaseToken({field1,field2,field3,value,password},done) {
  return function(dispatch,getState){
    let privateKey = "";

		//get private key
 		try {
	 		privateKey = decrypt(getState().main.account.privateKey, password, getState().main.account.salt, getState().main.account.iv);
      let account = fromPrivateKey(Buffer.from(privateKey, 'hex')).getAddressString();
			let val = web3.utils.toHex(web3.utils.toWei(String(value),'ether'));
			console.log(getNewWeb3Token().methods);
			console.log(getNewWeb3Token().address);
	    let dataOrder = getNewWeb3Token().methods.purchaseToken().encodeABI();
	    submitTransaction({account,to:getNewWeb3Token().address,value: val,data:dataOrder,privateKey},dispatch,(err,hash)=>{
	      console.log({err,hash});
	      done(err,hash);
				return;
	    });
    }
    catch(err){
      console.log({err});
      done('Incorrect Password',null);
      return;
    }

  }
}
