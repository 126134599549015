import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class Table extends Component {
  render() {
    let {
      headers,
      items,
      classes
    } = this.props;

    if(headers){
      headers = headers.map(h=>{
        return (
           <th className='center'> {`${h}`} </th>
        )
      });
    }

    return (
      <div className="col s12" >
        {headers &&
          <table className={classes} style={{tableLayout:"fixed",fontSize:"0.9em",height:'30px'}}>
            <thead>
              <tr>
                  {headers}
              </tr>
            </thead>
          </table>
        }
        <div style={{height:'300px',overflow:'scroll'}}>
        <table className={classes} style={{tableLayout:"fixed",fontSize:"0.9em",height:'30px'}}>
          <tbody>
            {items}
          </tbody>
        </table>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state,props) {
  return {

  };
}


export default connect(mapStateToProps, {})(Table);
